import { jwtDecode, JwtPayload } from 'jwt-decode'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import GlobalContext from '../../GlobalContext.tsx'

const getTokenPayload = (token: string) => {
  // Returns with the JwtPayload type
  return jwtDecode<JwtPayload>(token)
}

/** you should not use this Hook directly, use useContext(UserContext) instead */
export const useAuth = () => {
  const { org } = useContext(GlobalContext)
  const KEY = org + '_jwt'
  const [token, setToken] = useState<string | null>(localStorage.getItem(KEY))
  const [searchParams, setSearchparams] = useSearchParams()
  // remove key from the url
  const keyFromUrl = searchParams.get('key')
  useEffect(() => {
    if (keyFromUrl) {
      searchParams.delete('key')
      setSearchparams(searchParams)
    }
  }, [keyFromUrl, searchParams, setSearchparams])

  const setJwtToken = useCallback(
    (key: string | null) => {
      if (key === null) {
        localStorage.removeItem(KEY)
        setToken(null)
        return
      }
      setToken(key)
      localStorage.setItem(KEY, key)
    },
    [KEY],
  )

  const isLoggedIn = useCallback(() => {
    if (!token) {
      return false
    }
    const payload = getTokenPayload(token)

    if (payload.exp) {
      return payload.exp > Date.now() / 1000
    }

    return true
  }, [token])

  const expiresSoon = () => {
    if (!token) {
      return false
    }
    const payload = getTokenPayload(token)

    const REFRESH_BEFORE_DAYS = 4
    if (payload.exp) {
      return payload.exp < Date.now() / 1000 + 60 * 60 * 24 * REFRESH_BEFORE_DAYS
    }

    return false
  }
  const logout = () => {
    setJwtToken(null)
    //window.location.reload()
  }

  return { setJwtToken, isLoggedIn, logout, expiresSoon, token }
}
