import { useSnackbar } from 'notistack'
import { useCallback, useContext } from 'react'

import GlobalContext from '../../GlobalContext.tsx'

export const useFetch = (token: string | null) => {
  const { enqueueSnackbar } = useSnackbar()
  const { host, org } = useContext(GlobalContext)

  const fetchAdmin = useCallback(
    async (path: string, method = 'get', data?: any, options: { auth?: boolean; apikey?: string } = {}) => {
      const DEFAULT_OPTIONS = { auth: true }
      options = { ...DEFAULT_OPTIONS, ...options }
      const headers: HeadersInit = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
      if (options.auth) {
        // this is very crude, we need to change the API  Key thingie...

        if (options.apikey) {
          headers['Api-Key'] = options.apikey
        } else {
          const jwt = token
          if (jwt) {
            headers['Authorization'] = 'Bearer ' + jwt
          } else {
            enqueueSnackbar('No api key set...', { variant: 'error', preventDuplicate: true })
            throw new Error('No jwt token')
          }
        }
      }

      const url = new URL(host + '/' + org + path)
      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          url.searchParams.set(key, value as string)
        })
      }
      return fetch(url, {
        method,
        headers,
        body: method !== 'get' ? JSON.stringify(data) : undefined,
      })
        .catch(response => {
          console.log('Error fetching', response)
          enqueueSnackbar('Error fetching data from backend', { variant: 'error', preventDuplicate: true })
          throw new Error('Error fetching data from backend.')
        })
        .then(async response => {
          if (!response.ok) {
            if (response.status == 401) {
              enqueueSnackbar('Unauthorized access to backend', { variant: 'error', preventDuplicate: true })
              throw new Error('Unauthorized')
            }
            enqueueSnackbar('Error fetching data from backend. Status: ' + response.status, {
              variant: 'error',
              preventDuplicate: true,
            })
            throw new Error('Error fetching data from backend. Status: ' + response.status)
          }
          return response
        })
    },
    [enqueueSnackbar, token, host, org],
  )

  const fetchPublic = useCallback(
    (path: string, method = 'get', data?: any) => {
      return fetchAdmin(path, method, data, { auth: false })
    },
    [fetchAdmin],
  )

  return { fetchAdmin, fetchPublic }
}
